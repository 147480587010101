.container {
  padding: 30px 0 45px;
  width: 95%;
  margin: auto;
  overflow: hidden;
}

@media all and (max-width: 768px) {
  .container {
    width: 87%;
    display: block;
    padding: 20px 0;
    overflow: hidden;
  }
}

.mainWrapper {
  margin: 15px 0;
}

body h1.mainHeading {
  font-size: 22px;
  line-height: 37px;
  font-family: interstate, sans-serif, serif;
  font-weight: bolder;
}

.pageBorderTop,
.pageBorderBottom {
  display: block;
  width: 100%;
}
.pageBorderTop {
  margin: 0 auto 5px;
  border-bottom: 5px solid #212121;
}

.pageBorderBottom {
  margin: 0 auto;
  border-bottom: 10px solid #212121;
}

ul {
  list-style-position: initial;
  padding-left: 20px;
}
a {
  /* color: #026146; */
}
a:hover {
  color: #212121;
}

.num {
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
}
h1.legal {
  font-family: "Playfair Display", serif;
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-size: 35px;
  line-height: 1;
}

.legal ol li ul li {
  list-style-type: disc;
}

.legal ol {
  counter-reset: li;
  margin-left: 0;
  padding-left: 0;
}
.legal ol > li {
  position: relative;
  margin: 0 0 6px 1.25em;
  padding: 4px 8px;
  list-style: none;
  font-family: "Playfair Display", serif;
  font-size: 1.25em;
  font-weight: bold;
}
.legal ol > li:before {
  content: counter(li) ")";
  counter-increment: li;
  position: absolute;
  top: 2px;
  left: -1.75em;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 1.75em;
  margin-right: 8px;
  padding: 4px;
  font-weight: normal;
  padding-right: 0;
  font-size: 0.85em;
  font-family: "Helvetica Neue", Arial, sans-serif;
  text-align: center;
}
.legal li ol,
.legal li ul {
  margin-top: 6px;
}
.legal ol ol li:last-child {
  margin-bottom: 0;
}

.legal .slug {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0.01rem;
}
.legal p,
.legal ol > li > p,
.legal ul li {
  font-size: 16px;
  line-height: 1.4;
  text-align: justify;
  font-weight: normal;
}

span.rumble {
  content: "Rumble";
  text-transform: capitalize;
}
span.on {
  content: "On";
  text-transform: capitalize;
}

@media (min-width: 768px) {
  body h1.mainHeading {
    font-size: 36px;
    line-height: 80px;
  }
  .mainWrapper {
    margin: 30px 0;
  }
  /* border right in two column section */
  .borderMiddle {
    position: absolute;
    height: 100%;
    border-left: 1px solid rgb(187, 187, 187);
    right: 51%;
    padding: 0 !important;
  }
}

@media (min-width: 992px) {
  .mainWrapper {
    margin: 50px 0;
    position: relative;
  }
  .borderMiddle {
    right: 50%;
  }
}

@media (max-width: 767px) {
  .pageBorderTop {
    border-bottom-width: 1px;
    margin-bottom: 2px;
  }
  .pageBorderBottom {
    border-bottom-width: 4px;
    margin-bottom: 5px;
  }
}
