.uwy.userway_p1 .uai {
  /* top: 90%; */
  position: fixed;
  right: 5vh;
  top: 85vh;
}
.uwy.utb.userway_p7 {
  /* top: 90%; */
  position: fixed;
  right: 5vh;
  top: 85vh;
}
#userwayAccessibilityIcon {
  /* top: 90%; */
  position: fixed;
  right: 5vh;
  top: 85vh;
}
@media all and (max-width: 768px) {
  .uwy.utb.userway_p7 {
    /* top: 77% !important; */
    position: fixed;
    right: 5vh;
    top: 85vh;
  }
  #userwayAccessibilityIcon {
    /* top: 77% !important; */
    position: fixed;
    right: 5vh;
    top: 85vh;
  }
}
.cookieContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.5rem;
  background: #ffffff;
}
.cookieText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #000000;
}
.acceptButton {
  width: 10rem;
  height: 2rem;
  border-radius: 4px;
  margin: 0.5rem auto;
  background: #000000;
  color: #ffffff;
}
.textHover {
  text-decoration: none;
  color: #000000;
}
.textHover:hover {
  text-decoration: underline;
  color: #000000;
}
